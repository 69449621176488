import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allContentfulPost.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Poetry and short stories from the lens of a bi-racial woman"
          keywords={[
            `cultural poems`,
            `inspirational poems`,
            `poems about loving`,
            `poems about life`,
            `poems about culture`,
            `poems about identity`,
          ]}
        />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.title
          return (
            <div key={node.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={'posts/' + node.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.date}</small>
              <p>{node.caption}</p>
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          date(formatString: "MMMM DD, YYYY")
          slug
          caption
        }
      }
    }
  }
`
